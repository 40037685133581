.header {
    top: 0;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem; /* 64px, similar to h-16 in Tailwind */
    padding: 0 1rem; /* Adds some spacing around the content */
    border-bottom: 1px solid #e5e7eb; /* Light gray border for separation */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for better visibility */
  }
    
.header-title {
    font-size: 1.25rem; /* 20px, similar to text-xl in Tailwind */
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.profile-button {
    margin-right: 8px;
}

.layout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

.content {
    flex: 1;
    overflow-y: auto; /* Make this section scrollable */
}

.logo {
    margin-right: 6px;
    border-radius: 8px;
}

.app-title {
    display: flex;
    align-items: center;
    gap: 36px;
    margin-right: 24px;
}

.share-button {
    margin-right: 4px;
}

@media (max-width: 768px) {
    .app-title {
      display: none;
    }

    .logo {
        margin-right: 24px;
    }
  }
